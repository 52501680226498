import * as React from 'react'
import View, { Props as ViewProps } from '@packages/common/src/components/View'

type Props = ViewProps & {
  src: string
}

const Image = ({ src, ...props }: Props) => (
  <View as="img" nativeProps={{ src }} {...props} />
)

export default Image
