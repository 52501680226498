import * as React from 'react'
import View from './View'
import { Style } from '../providers/style/types'
import { TestableProps } from '@packages/common/src/components/Stylo'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'

export type ClickableOpacityProps = TestableProps & {
  cursor?: string
  isDisabled?: boolean
  onAuxClick?: React.EventHandler<React.MouseEvent>
  onFocus?: React.EventHandler<React.FocusEvent>
  onClick?: React.EventHandler<React.MouseEvent>
  onDoubleClick?: React.EventHandler<React.MouseEvent>
  onMouseDown?: React.EventHandler<React.MouseEvent>
  onMouseUp?: React.EventHandler<React.MouseEvent>
  children?: React.ReactNode
  style?: Style
  title?: string
  tabIndex?: number | null
  [key: string]: any
}

const styles = cmpStyles({
  adjustCursor: ({ cursor }) => ({
    cursor,
    userSelect: 'none',
  }),
})

const ClickableOpacity: React.FC<ClickableOpacityProps> = ({
  cursor = 'pointer',
  onAuxClick,
  onClick,
  onMouseDown,
  onMouseUp,
  onDoubleClick,
  isDisabled,
  onFocus,
  style,
  children,
  title,
  tabIndex = 0,
  ...restProps
}: ClickableOpacityProps) => {
  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      // escape or space
      ;(e.target as HTMLDivElement).click()
      e.stopPropagation()
      e.preventDefault()
    }
  }, [])

  return (
    <View
      style={style ? [style, styles.adjustCursor] : styles.adjustCursor}
      nativeProps={{
        title,
        onAuxClick: isDisabled ? undefined : onAuxClick,
        onClick: isDisabled ? undefined : onClick,
        onMouseUp: isDisabled ? undefined : onMouseUp,
        onMouseDown: isDisabled ? undefined : onMouseDown,
        onDoubleClick: isDisabled ? undefined : onDoubleClick,
        onKeyDown: isDisabled ? undefined : onKeyDown,
        onFocus: isDisabled ? undefined : onFocus,
        tabIndex: isDisabled ? undefined : tabIndex,
      }}
      cursor={cursor}
      isDisabled={isDisabled}
      {...restProps}>
      {children}
    </View>
  )
}

export default ClickableOpacity
